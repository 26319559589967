<template>
  <div>
    <topslogan/>
    <div :style="{backgroundImage: 'url('+server_url+categoryinfoData.cover_image+')'}" class="top_head">
      <topnav></topnav>
    </div>
    <div class="breadcrumb_bg">
      <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item><router-link to="/"> <a-icon type="home" /></router-link></a-breadcrumb-item>
        <a-breadcrumb-item>{{categoryinfoData.name}}</a-breadcrumb-item>
        <a-breadcrumb-item>{{articleData.title}}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="article_info ql-container ql-snow">
        <div class="tilte"> {{articleData.title}}</div>
<!--        <div class="top_img"> <img-->
<!--            :alt="articleData.title"-->
<!--            :src="server_url+articleData.cover_image"-->
<!--        /></div>-->
      <div class="video-content" v-if="medialink">
        <div v-show="video_pay" class="video_pay">
          <div>该视频为会员可见，请成为本网站会员后观看全部内容。<br/><br/>
            <a-button class="btn-danger" shape="round" size="large" type="danger" @click="goPay">
              成为会员
              <a-icon type="arrow-right"/>
            </a-button></div>
        </div>
<!--        {{server_url1+medialink}}-->

        <h1>{{mediatitle}}</h1>
        <video
            style="width:640px;height:480px"
            :src="server_url+medialink"
            @timeupdate="updateTime"
            ref="myVideo"
            preload:metadata
            class="swiper-slide-img"
            id="myVideo"
            controls controlslist="nodownload noremoteplayback"
            oncontextmenu = "return false">
          您的浏览器不支持 video 标签。
        </video>
        <img
            src="@/assets/playbtn.png"
            alt=""
            @click="operateVideo"
            class="pointer operate-btn"
            :class="{ 'fade-out': videoState }"
            v-show="videoState"
        />
      </div>
        <div class="content">
          <div class="ql-editor article_content article_info_content"  v-html="content" v-if="articleData.type===1||articleData.type===4"></div>
          <div v-if="articleData.type===4" class="content_join">
            <a-button class="btn-danger" shape="round" size="large" type="danger" @click="opencourse_pay">
              线上报名
              <a-icon type="arrow-right"/>
            </a-button>
          </div>
          <div class="ql-editor article_content article_info_content"  v-html="short_content" v-if="articleData.type===3"></div>
          <div v-if="articleData.type===3" class="content_pay">
            <div>该文章为会员可见，请成为本网站会员后观看全部内容。<br/><br/>
              <a-button class="btn-danger" shape="round" size="large" type="danger" @click="goPay">
              成为会员
              <a-icon type="arrow-right"/>
            </a-button></div>
          </div>
        </div>
    </div>
    <bottom></bottom>
    <a-modal v-model="course_pay" :centered="true" :footer="null" width="65%" title="填写报名资料">
      <a-form-item class="infoitem" label="名字">
        <a-input v-model="userinfo.names" class="infoinput"/>
      </a-form-item>
      <a-form-item class="infoitem" label="性别">
        <a-select v-model="userinfo.sex" class="infoinput" @change="Changesex">
          <a-select-option value="1">
            男
          </a-select-option>
          <a-select-option value="2">
            女
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="infoitem" label="身份证号码">
        <a-input v-model="userinfo.id_card" class="infoinput"/>
      </a-form-item>
<!--      <a-form-item v-if="userinfo.more_info" class="infoitem" label="工作单位">-->
<!--        <a-input v-model="userinfo.id_card.position" class="infoinput"/>-->
<!--      </a-form-item>-->
      <a-button class="btn-danger" shape="round" size="large" type="danger" @click="getCourse">
        线上报名
        <a-icon type="arrow-right"/>
      </a-button>
    </a-modal>
  </div>
</template>

<script>

import Config from "@/config";
import topnav from "@/views/topnav";
import topslogan from "@/views/topslogan";
// import PlayerVideo from "./PlayerVideo.vue";
import bottom from "@/views/bottom";
import Storage from "@/common/storage";
let pinyin = require('js-pinyin');
export default {
  components: {topnav,bottom,topslogan},
  name: "category_list",
  props: [],
  data() {
    return {
      medialink: "",
      server_url: Config.data.server_url,
      server_url1: Config.data.server_url1,
      categoryinfoData: "",
      language: localStorage.language,
      show_id: "",
      content: "",
      short_content: "",
      categoryData: [],
      articleData: [],
      current: 1,
      videoState: true,
      video_pay: false,
      course_pay: false,
      loading: false,
      userinfo:{
        sex:"",
        names:"",
        name_code:"",
        id_card:"",
      },
      videoOptions: {
        autoplay: true,

        controls: true,
        sources: [
          {
            src:"",
            type: "video/mp4"
          }
        ]
      },
      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 1,
      },
    }
  },
  created() {

    console.log(this.$route.query.articleid)
    this.article_id = this.$route.query.articleid
    Promise.all([ this.getarticleinfo(this.article_id)]).then(() => {

      this.loading = false;
    }).catch((error) => {
      console.log(error)
    })


  },
  mounted() {
      
  },
  methods: {
    goPay(){
      this.$router.push({
        path: '/payvip',
        query: {
          choose: 1,
          t:Date.now(),
        }
      });
    },
    updateTime() {
      let s=this.$refs.myVideo.currentTime;//获取当前播放时间
      console.log(s+'=======获取当前播放时间')
      if(s>=60){
        console.log('超过60秒禁止播放')
        this.$refs.myVideo.pause();
        this.video_pay=true;
        return;
      }
    },
    Changesex(value) {
      this.userinfo.sex = value
    },
    // 操作视频播放、暂停
    operateVideo() {
      if (!this.medialink) {
        // this.$message({ message: "暂无视频资源，请查看其他视频！" });
        return false;
      }
      if (this.$refs.myVideo.paused) {
        this.$refs.myVideo.play();
        this.videoState = true;
      } else {
        this.$refs.myVideo.pause();
        this.videoState = false;
      }
    },
    async getarticleinfo(article_id) {
      console.log("请求id", article_id);
      this.articleData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/article/info", {
        params: {
          article_id: article_id,
          language: this.language,
        }
      });

      if (result.status === true) {
        this.articleData = result.data;
        this.content = JSON.parse(result.data.content);
        this.short_content = JSON.parse(result.data.short_content);
        console.log("result", result.data);
        if(result.data.more_info_json.medialink){
          // this.medialink = result.data.more_info_json.medialink;
          this.mediainfo(result.data.more_info_json.medialink)
          this.mediatitle = result.data.more_info_json.mediatitle;
        }

        this.getcategoryinfo(result.data.articles_category_id)
        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    async onChange(pagination) {
      console.log(pagination.current)
    },
    async opencourse_pay() {
      this.course_pay = true
    },
    async getcategoryinfo(articles_category_id) {
      console.log("请求id", articles_category_id);
      this.categoryinfoData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/articleCategory/info", {
        params: {

          articles_category_id: articles_category_id,
          language: this.language,
        }
      });

      if (result.status === true) {
        this.categoryinfoData = result.data;


        // if(result.data.child.length === 0){
        //   console.log("没有子栏目" );
        //   this.getcategorylist(1, 20,articles_category_id, 1, "")
        // }else{
        //   console.log("有子栏目",);
        //   this.show_id=result.data.child[0].id
        //   this.getcategorylist(1, 20,this.show_id, 1, "")
        // }


        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    async mediainfo(code) {
      console.log("请求code", code);
      this.loading = true;
      let result = await this.$get("/api/customer/videos/play", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          video_code: code,
        }
      });
      this.medialink=result
      console.log("geturl" ,result);

      this.loading = false;
    },
    async getcategorylist(page, limit, articles_category_id,getall,is_show) {
      console.log("请求id", articles_category_id);
      this.articlesData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/article/list", {
        params: {
          page: page,
          limit: limit,
          articles_category_id: articles_category_id,
          get_all: getall,
          is_show: is_show,
          language: this.language,
        }
      });

      if (result.status === true) {

        this.articlesData = result.data.list;
        let pagination = { ...this.pagination };
        pagination.total = result.data.total;
        this.pagination = pagination;
        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },

    async getCourse() {
      console.log("getCourse", this.language)
      this.loading = true;
      this.navbar = [];

      let now = Date.now();
      let result = await this.$post("/api/customer/order/buyCourse", {

        // token: Storage.token,
        uid: Storage.uid,
        token: Storage.token,
        articles_id: this.article_id,
        account_base_info: {
          sex: this.userinfo.sex,
          names: this.userinfo.names,
          name_code: pinyin.getFullChars(this.userinfo.names),
          id_card: this.userinfo.id_card,
        },
        t: now,

      });

      if (result.status === true) {
        this.$message.success('支付信息已经提交');
        return;
      }

      this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.top_head{
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  height: 560px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size:cover;

}
.breadcrumb_bg{
  background: #F2F4F5;
  padding-top: 18px;
  height: 64px;
}
.breadcrumb{
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;

}
.title{
  color: #fff;
}
.active{
  color: #fff;
  background: #CE1126;
}
.article_info{
  max-width: 1248px;
  margin-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
  .tilte{
    margin-top: 80px;
    margin-bottom: 34px;
    font-size:40px;
    font-weight: bold;
    color: #000;
  }
  .top_img{
    max-height:450px;
    overflow: hidden;
    img{
      width: 100%;
      height: auto!important;
    }
  }
  .article_info_content{
    /deep/ img{
      max-width: 1248px;
      height: auto
    }
  }
  .article_content{
    margin-top: 34px;
    font-size: 18px;
    line-height: 32px;
    text-indent:2em;
    margin-bottom: 100px;
  }
}
.video-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .operate-btn {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    cursor: pointer;
  }
  .operate-btn:hover {
    opacity: 0.8;
  }
  .fade-out {
    opacity: 0;
  }
}
.video_pay{
  display: flex;
  align-items: center;justify-content: center;
  color:white;
  height: 480px;
  width: 640px;
  position: absolute;
  z-index: 10000;
  background-color:rgba(0,0,0,0.5)
}
.content{
  position: relative;
  padding-bottom: 0px;
}
.content_pay{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color:#000;
  height: 150px;
  width: 100%;
  position: absolute;
  font-size:16px;
  z-index: 10000;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #e0e0e0 90%);
}
.content_join{
  display: flex;
  //align-items: center;
  justify-content: center;
  text-align: center;
  color:#000;
  height: 150px;
  width: 100%;
  //position: absolute;
  font-size:16px;
  z-index: 10000;
  //bottom: 0;
  //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #e0e0e0 90%);
}
.infoinput {
  border-radius: 15px;
  height: 30px;
}
</style>